<!--城市联动-->
<template>
  <div :id="id" :ref="id" class="c-city-view">
    <div class="c-city-view_input">
      <!--    省-->
      <znl-input
        ref="province"
        v-model="editItems.province"
        :disabled="disabled"
        :select-options="provinceOptions"
        :width="widths.provinceWidth"
        border
        clearable
        form-type="select"
        placeholder="省"
        @change="onChangeProvince">
      </znl-input>
    </div>
    <div class="c-city-view_input">
      <!--    市-->
      <znl-input
        ref="city"
        v-model="editItems.city"
        :disabled="disabled"
        :select-options="cityOptions"
        :width="widths.cityWidth"
        border
        clearable
        form-type="select"
        placeholder="市"
        @change="onChangeCity">
      </znl-input>
    </div>
    <div class="c-city-view_input">
      <!--    区-->
      <znl-input
        ref="area"
        v-model="editItems.area"
        :disabled="disabled"
        :select-options="areaOptions"
        :width="widths.areaWidth"
        border
        clearable
        form-type="select"
        placeholder="区"
        @change="onChangeArea">
      </znl-input>
    </div>

  </div>
</template>
<script>
import {getCityData, cityDataMap} from "@/utils/city";

export default {
  name: 'CityView',
  props: {
    widths: {
      type: Object,
      default() {
        return {
          provinceWidth: '160px',
          cityWidth: '160px',
          areaWidth: '160px'
        }
      }
    },
    paramsItems: {
      type: Object,
      default() {
        return {
          province: null,
          city: null,
          area: null
        };
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    },
    id: {
      type: String
    }
  },
  data() {
    return {
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      editItems: {
        province: '',
        city: '',
        area: ''
      },
    }
  },
  methods: {
    onChangeProvince(value) {
      this.onResCityOrArea()
      this.$nextTick(async () => {
        this.cityOptions = await getCityData(value.key)
        this.paramsItems.province = value
      })
    },
    onChangeCity(value) {
      this.onResArea()
      this.$nextTick(async () => {
        this.areaOptions = await getCityData(value.key)
        this.paramsItems.city = value
      })
    },
    onChangeArea(value) {
      this.$nextTick(async () => {
        this.paramsItems.area = value
      })
    },
    onResCityOrArea() {
      this.$refs.city.clear()
      this.editItems.city = ''
      this.editItems.area = ''
      this.paramsItems.city = null
      this.onResArea()
    },
    onResArea() {
      this.$refs.area.clear()
      this.editItems.area = ''
      this.paramsItems.area = null
    }

  },
  async created() {
    this.provinceOptions = await getCityData(1)
  },

  watch: {
    paramsItems: {
      async handler(val) {
        console.log(val)

        this.provinceOptions = await getCityData(1)

        this.$nextTick(async () => {
          if (val.province === null) {
            this.$refs.province.clear()
            this.$refs.city.clear()
            this.$refs.area.clear()
          }
          if (val.city === null) {
            this.$refs.city.clear()
            this.$refs.area.clear()
          }
          if (val.area === null) {
            this.$refs.area.clear()
          }
        })


        const testType = (value) => {
          console.log(value)
          return typeof value == 'string'
        }
        const setKey = (value) => {
          let key = null
          let item = value
          if (testType(item)) {
            console.log(item)
            const data = cityDataMap.find(k => k.value === item)
            if (data) {
              key = data.key
            }
          } else {
            key = item.key
          }
          return key
        }
        for (let key in val) {
          const item = val[key]
          if (item !== null) {

            const code = setKey(item)
            this.editItems[key] = code
            if (key === 'province') {
              this.cityOptions = await getCityData(code)
            }
            if (key === 'city') {
              this.areaOptions = await getCityData(code)
            }
          }
        }

      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style lang="scss">
.c-city-view {
  .c-city-view_input {
    display: inline-block;
  }
}
</style>
