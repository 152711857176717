<template>
  <div :class="isDetail ? 'p-client-detail' : ''" class="p-client-edit">

    <!--    添加返回-->
    <!-- <go-back-item></go-back-item> -->
    <!--    统计-->
    <section>
      <score-view :scoreList="scoreList"></score-view>
    </section>
    <!--    客户基本信息-->
    <section class="p-client-edit-info">
      <div class="p-client-edit-info_left">
        <p class="p-client-edit_title"><span>客户基本信息： </span></p>
        <ul class="p-client-edit_ul">
          <li class="p-client-edit_li">
            <znl-input
                v-model="editItem.clientCode"
                v-required="requiredData['clientCode_required']"
                :disabled="isDetail"
                border
                title="客户编码:"
                title-width="70px"
                width="260px"
                @blur="verifyCode"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`clientCode_score`]"
                  style="color: #0a76a4"
              ></span
              ></znl-input>
            <znl-input
                v-model="editItem.clientName"
                v-required="requiredData['clientName_required']"
                :disabled="isDetail"
                border
                title="客户名称:"
                title-width="70px"
                @blur="verifyName"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`clientName_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
            <znl-input
                v-model="editItem.clientShortName"
                v-required="requiredData['clientShortName_required']"
                :disabled="isDetail"
                border
                title="客户简称:"
                title-width="70px"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`clientShortName_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
          </li>
          <li class="p-client-edit_li">
            <label  @mouseenter="selectLog('CompanyPhone', editItem.contactId)" @mouseleave="leave('CompanyPhone', editItem.contactId)">
              <znl-input
                  v-model="editItem.clientPhone"
                  v-required="requiredData['clientPhone_required']"
                  :disabled="isDetail"
                  border
                  title="固定电话:"
                  title-width="70px"
                  width="260px"
              >
                <span
                    slot="suffix"
                    v-score="scoreData[`clientPhone_score`]"
                    style="color: #0a76a4"
                ></span>
              </znl-input>
            </label>

            <label @mouseenter="selectLog('CustomerQQ', editItem.contactId)" @mouseleave="leave('CustomerQQ', editItem.contactId)">
              <znl-input
                  v-model="editItem.clientCustomerQQ"
                  v-required="requiredData['CustomerQQ_required']"
                  :disabled="isDetail"
                  border
                  title="公司QQ:"
                  title-width="70px"
              >
                <span
                    slot="suffix"
                    v-score="scoreData[`CustomerQQ_score`]"
                    style="color: #0a76a4"
                ></span>
              </znl-input>
            </label>

            <label @mouseenter="selectLog('CustomerPhone', editItem.contactId)" @mouseleave="leave('CustomerPhone', editItem.contactId)">
              <znl-input
                  v-model="editItem.clientCustomerPhone"
                  v-required="requiredData['CustomerPhone_required']"
                  :disabled="isDetail"
                  border
                  title="公司手机号:"
                  title-width="70px"
              >
                <span
                    slot="suffix"
                    v-score="scoreData[`CustomerPhone_score`]"
                    style="color: #0a76a4"
                ></span>
              </znl-input>
            </label>
          </li>
          <li class="p-client-edit_li">

            <label @mouseenter="selectLog('Fax', editItem.contactId)" @mouseleave="leave('Fax', editItem.contactId)">
              <znl-input
                  v-model="editItem.clientFax"
                  v-required="requiredData['clientFax_required']"
                  :disabled="isDetail"
                  border
                  title="公司传真:"
                  title-width="70px"
                  width="260px"
              >
                <span
                    slot="suffix"
                    v-score="scoreData[`clientFax_score`]"
                    style="color: #0a76a4"
                ></span>
              </znl-input>
            </label>

            <p class="m-inline-block">
              <znl-input
                  v-model="editItem.clientIsUse"
                  v-required="requiredData['clientIsUse_required']"
                  :disabled="isDetail"
                  :placeholder="isDetail ? '' : '请选择'"
                  :select-options="clientIsUseOption"
                  border
                  clearable
                  form-type="select"
                  title="启用状态:"
                  title-width="70px"
              >
              </znl-input>
            </p>
          </li>
          <li v-if="!isAddress" class="p-client-edit_li">
            <span
                v-required="requiredData['clientProvince_required']"
                class="p-client-edit_li--span"
            >客户地址:
            </span>
            <p class="m-inline-block">
              <city-view
                  ref="clientAddressView"
                  :disabled="isDetail"
                  :paramsItems="clientAddress"
                  :widths="cityWidth"
              ></city-view>
            </p>
            <znl-input
                v-model="editItem.clientAddress"
                :disabled="isDetail"
                border
                placeholder="详细地址"
                width="285px"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`clientCompaneyAddress_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
            <el-checkbox
                v-if="!isDetail && receiveAddressArry.length === 1"
                @change="onMergeAddress"
            >同步到收货地址
            </el-checkbox>
          </li>
          <!-- v-score="scoreData[`clientReceiveProvince_score`]"-->
          <!--         <li v-if="!isAddress" class="p-client-edit_li">-->

          <!--            <span class="p-client-edit_li&#45;&#45;span">收货地址: </span>-->
          <!--            <p class="m-inline-block">-->
          <!--              <city-view ref="receiveAddressView" :disabled="isDetail" :paramsItems="receiveAddress"-->
          <!--                         :widths="cityWidth"></city-view>-->
          <!--            </p>-->
          <!--            <znl-input v-model="editItem.clientReceiveAddress" :disabled="isDetail" :placeholder="isDetail? '': '详细地址'"-->
          <!--                       border-->
          <!--                       width="285px">-->
          <!--              <span slot="suffix" v-score="scoreData[`clientReceivingAddress_score`]" style="color: #0a76a4"></span>-->
          <!--            </znl-input>-->
          <!--            <znl-button v-if="!isDetail" :height="28" :width="110" style="vertical-align: top" style-type="minor">-->
          <!--              更多收货地址-->
          <!--            </znl-button>-->
          <!--          </li>-->

          <li v-if="!isAddress" class="p-client-edit_li">
            <div
                v-for="(item, index) in receiveAddressArry"
                :key="item.id"
                :ref="`address_${item.id}`"
                class="p-client-edit_li_addressblock"
            >
              <span class="p-client-edit_li--span">收货地址: </span>
              <p class="m-inline-block">
                <city-view
                    :id="`receiveAddressView-${index}`"
                    :ref="`receiveAddressView-${index}`"
                    :disabled="isDetail"
                    :paramsItems="item.Pca"
                    :widths="cityWidth"
                ></city-view>
              </p>
              <znl-input
                  v-model="item.Address"
                  :disabled="isDetail"
                  :placeholder="isDetail ? '' : '详细地址'"
                  border
                  width="285px"
              >
                <span
                    slot="suffix"
                    v-score="scoreData[`clientReceivingAddress_score`]"
                    style="color: #0a76a4"
                ></span>
              </znl-input>
              <znl-button
                  v-if="!isDetail && index === 0"
                  :height="28"
                  :width="110"
                  style="vertical-align: top"
                  style-type="minor"
                  @click="moreAddress"
              >
                更多收货地址
              </znl-button>
              <span
                  v-if="!isDetail && index !== 0"
                  class="el-icon-close"
                  @click="removeAddress(index)"
              ></span>
            </div>
          </li>

          <li class="p-client-edit_li">
            <znl-input
                v-model="editItem.clientResume"
                v-required="requiredData['clientResume_required']"
                :disabled="isDetail"
                border
                title="客户简介"
                title-width="70px"
                type="textarea"
                width="360px"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`clientResume_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
            <znl-input
                v-model="editItem.clientRemark"
                v-required="requiredData['clientRemark_required']"
                :disabled="isDetail"
                border
                title="客户备注:"
                title-width="70px"
                type="textarea"
                width="408px"
            ><span
                slot="suffix"
                v-score="scoreData[`clientRemark_score`]"
                style="color: #0a76a4"
            ></span
            ></znl-input>
          </li>
          <li class="p-client-edit_li">
            <!-- <znl-input v-model="editItem.OrgName" :disabled="isDetail"
                       :select-options="clientOrganizationList" border clearable form-type="select" placeholder=""
                       title="所属机构:" title-width="70px" width="260px"></znl-input> -->
            <znl-input v-model="editItem.OrgName" border disabled placeholder=""
                       title="所属机构:" title-width="70px"></znl-input>
            <znl-input v-model="editItem.DeptName" border disabled placeholder=""
                       title="所属部门:" title-width="70px"></znl-input>
            <znl-input v-model="editItem.UserName" border disabled placeholder=""
                       title="所属人员:" title-width="70px"></znl-input>
          </li>
        </ul>
      </div>
      <div class="p-client-edit_right">
        <div class="p-client-edit_right-follow">
          <div class="header">
            <p>跟进概况</p>
          </div>
          <div class="box">
            <el-collapse v-if="followList.length" accordion>
              <el-collapse-item
                  v-for="(item, index) in followList"
                  :key="index"
              >
                <template slot="title">
                  <p>
                    <span>{{ item.followDate }}</span>
                    <span style="margin-left: 10px">{{ item.followName }}</span>
                  </p>
                </template>

                <section class="box-content">
                  <div class="box-content_div">
                    <p>
                      <span>跟进方式:</span>
                      <i>{{ item.followType | followSource }}</i>
                    </p>
                    <p>
                      <span>预计下次跟进时间:</span>
                      <i>{{ item.followNextDate }}</i>
                    </p>
                  </div>
                  <div class="box-content_div">
                    <p>
                      <span>预计下单时间：</span>
                      <i>{{ item.followOrderDate }}</i>
                    </p>
                  </div>
                  <div class="box-content_div">
                    <p>
                      <span>跟进内容：</span>
                      <i>{{ item.followRemark }}</i>
                    </p>
                  </div>
                </section>
              </el-collapse-item>
            </el-collapse>
            <p v-else class="no-data">
              <span>暂无跟进数据</span>
            </p>
          </div>
        </div>
        <div class="p-client-edit_right-total">
          <ul>
            <li>
              <span>下单总次数: </span><i @click="SelectCount('SalesOrder')">{{ orderCountItem.OrderCount }}</i>
              <span class="span2">周: </span><i>{{ orderCountItem.ThisWeekCount }}</i>
              <span class="span2">月: </span><i>{{ orderCountItem.ThisMonthCount }}</i>
              <span class="span2">季: </span><i>{{ orderCountItem.ThisQuarterCount }}</i>
            </li>
            <li>
              <span>询价总次数: </span><i>{{ InquireCountItem.OrderCount }}</i>
              <span class="span2">周: </span><i>{{ InquireCountItem.ThisWeekCount }}</i>
              <span class="span2">月: </span><i>{{ InquireCountItem.ThisMonthCount }}</i>
              <span class="span2">季: </span><i>{{ InquireCountItem.ThisQuarterCount }}</i>
            </li>
            <li>
              <span>跟进总次数: </span><i>{{ editItem.clientFollowCount }}</i>
              <span class="span2">周: </span><i>{{ editItem.clientFollowWeekCount }}</i>
              <span class="span2">月: </span><i>{{ editItem.clientFollowMonthCount }}</i>
              <span class="span2">季: </span><i>{{ editItem.clientFollowSeasonCount }}</i>
            </li>
            <li>
              <span>公海获取总次数: </span
              ><i>{{ editItem.clientCommonCount }}</i>
              <span class="span2">周: </span><i>{{ editItem.clientCommonWeekCount }}</i>
              <span class="span2">月: </span><i>{{ editItem.clientCommonMonthCount }}</i>
              <span class="span2">季: </span><i>{{ editItem.clientCommonSeasonCount }}</i>
            </li>
            <li>
              <span>出库总金额: </span
              ><i>{{ editItem.Amount }}</i>
              <span class="span2">月: </span><i>{{ editItem.ThisMonthAmount }}</i>
              <span class="span2">季: </span><i>{{ editItem.ThisQuarterAmount }}</i>
              <span class="span2">年: </span><i>{{ editItem.ThisYearAmount }}</i>
            </li>
            <li>
              <span>出库总利润: </span
              ><i>{{ editItem.ProfitAmount }}</i>
              <span class="span2">月: </span><i>{{ editItem.ThisMonthProfitAmount }}</i>
              <span class="span2">季: </span><i>{{ editItem.ThisQuarterProfitAmount }}</i>
              <span class="span2">年: </span><i>{{ editItem.ThisYearProfitAmount }}</i>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!--    联系人信息-->
    <section class="p-client-edit-contacts">
      <div class="header">
        <p class="p-client-edit_title"><span>客户联系人与标签： </span></p>
        <p v-if="!isDetail">
          <el-button type="primary" @click="onAddContact">新增联系人</el-button>
        </p>
      </div>

      <div>
        <el-tabs
            v-model="editableTabsValue"
            :closable="!isDetail"
            type="border-card"
            @tab-remove="onDeleteContact"
        >
          <el-tab-pane
              v-for="item in editItem.contactList"
              :key="item.contactIndex"
              :label="`联系人${item.contactIndex}`"
              :name="item.contactIndex"
          >
            <section>
              <ul class="c-client-edit--ul">
                <li class="c-client-edit--li">
                  <znl-input
                      v-model="item.contactName"
                      v-required="requiredData['contactName_required']"
                      :disabled="isDetail"
                      border
                      title="联系人名称:"
                      title-width="100px"
                      width="300px"
                  >
                    <span
                        slot="suffix"
                        v-score="scoreData[`contactName_score`]"
                        style="color: #0a76a4"
                    ></span>
                  </znl-input>

                  <div
                      v-selectScore="scoreData[`contactGender_score`]"
                      class="m-inline-block"
                  >
                    <znl-input
                        v-model="item.contactGender"
                        v-required="requiredData['contactGender_required']"
                        :disabled="isDetail"
                        :placeholder="isDetail ? '' : '请选择'"
                        :select-options="genderOptions"
                        border
                        clearable
                        form-type="select"
                        title="性别:"
                        title-width="100px"
                        width="300px"
                    >
                    </znl-input>
                  </div>

                  <div
                      v-selectScore="scoreData[`contactBirthday_score`]"
                      class="m-inline-block"
                  >
                    <znl-input
                        v-model="item.contactBirthday"
                        v-required="requiredData['contactBirthday_required']"
                        :disabled="isDetail"
                        border
                        clearable
                        form-type="datepicker"
                        title="生日:"
                        title-width="100px"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        width="300px"
                    >
                    </znl-input>
                  </div>

                  <label @mouseenter="selectLog('Phone', item.contactId)" @mouseleave="leave('Phone', item.contactId)">
                    <znl-input
                        v-model="item.contactPhone"
                        v-required="requiredData['contactPhone_required']"
                        :disabled="isDetail"
                        border
                        title="手机:"
                        title-width="100px"
                        width="300px"
                    >
                      <span
                          slot="suffix"
                          v-score="scoreData[`contactPhone_score`]"
                          style="color: #0a76a4"
                      ></span>
                    </znl-input>
                  </label>
                  <label @mouseenter="selectLog('MobilePhone', item.contactId)" @mouseleave="leave('MobilePhone', item.contactId)">
                    <znl-input
                        v-model="item.contactMobilePhone"
                        v-required="requiredData['contactPhone_required']"
                        :disabled="isDetail"
                        class="Mobilephonestyle"
                        border
                        title="固定电话:"
                        title-width="100px"
                        width="300px"
                    >
                      <span
                          slot="suffix"
                          v-score="scoreData[`contactPhone_score`]"
                          style="color: #0a76a4"
                      ></span>
                    </znl-input>
                  </label>
                  
                </li>
                <li class="c-client-edit--li">
                  <label @mouseenter="selectLog('QQ', item.contactId)" @mouseleave="leave('QQ', item.contactId)">
                    <znl-input
                        v-model="item.contactQQ"
                        v-required="requiredData['contactQQ_required']"
                        :disabled="isDetail"
                        border
                        title="QQ:"
                        title-width="100px"
                        width="300px"
                    >
                      <span
                          slot="suffix"
                          v-score="scoreData[`contactQQ_score`]"
                          style="color: #0a76a4"
                      ></span>
                    </znl-input>
                  </label>
                  <label @mouseenter="selectLog('WeChat', item.contactId)" @mouseleave="leave('WeChat', item.contactId)">
                    <znl-input
                        v-model="item.contactWechat"
                        v-required="requiredData['contactWechat_required']"
                        :disabled="isDetail"
                        border
                        title="微信:"
                        title-width="100px"
                        width="300px"
                    >
                      <span
                          slot="suffix"
                          v-score="scoreData[`contactWechat_score`]"
                          style="color: #0a76a4"
                      ></span>
                    </znl-input>
                  </label>
                  <label >
                    <znl-input
                        v-model="item.contactWW"
                        v-required="requiredData['contactWW_required']"
                        :disabled="isDetail"
                        border
                        title="旺旺:"
                        title-width="100px"
                        width="300px"
                    >
                      <span
                          slot="suffix"
                          v-score="scoreData[`contactWW_score`]"
                          style="color: #0a76a4"
                      ></span>
                    </znl-input>
                  </label>
                  <label >
                    <znl-input
                        v-model="item.contactEmail"
                        v-required="requiredData['contactEmail_required']"
                        :disabled="isDetail"
                        border
                        title="Email:"
                        title-width="100px"
                        width="300px"
                    >
                      <span
                          slot="suffix"
                          v-score="scoreData[`contactEmail_score`]"
                          style="color: #0a76a4"
                      ></span>
                    </znl-input>
                  </label>
                </li>
                <li class="c-client-edit--li">
                  <znl-input
                      v-model="item.contactSalesQQ"
                      v-required="requiredData['contactSalesQQ_required']"
                      :disabled="isDetail"
                      border
                      title="对接业务QQ:"
                      title-width="100px"
                      width="300px"
                  >
                    <span
                        slot="suffix"
                        v-score="scoreData[`contactSalesQQ_score`]"
                        style="color: #0a76a4"
                    ></span>
                  </znl-input>
                  <znl-input
                      v-model="item.contactSalesWechat"
                      v-required="requiredData['contactSalesWechat_required']"
                      :disabled="isDetail"
                      border
                      title="对接业务微信:"
                      title-width="100px"
                      width="300px"
                  >
                    <span
                        slot="suffix"
                        v-score="scoreData[`contactSalesWechat_score`]"
                        style="color: #0a76a4"
                    ></span>
                  </znl-input>
                  <znl-input
                      v-model="item.contactSalesWW"
                      v-required="requiredData['contactSalesWW_required']"
                      :disabled="isDetail"
                      border
                      title="对接业务旺旺:"
                      title-width="100px"
                      width="300px"
                  >
                    <span
                        slot="suffix"
                        v-score="scoreData[`contactSalesWW_score`]"
                        style="color: #0a76a4"
                    ></span>
                  </znl-input>
                  <znl-input
                      v-model="item.contactSalesEmail"
                      v-required="requiredData['contactSalesEmail_required']"
                      :disabled="isDetail"
                      border
                      title="对接业务Email:"
                      title-width="100px"
                      width="300px"
                  >
                    <span
                        slot="suffix"
                        v-score="scoreData[`contactSalesEmail_score`]"
                        style="color: #0a76a4"
                    ></span>
                  </znl-input>
                </li>
              </ul>
              <ul class="c-client-edit--ul">
                <li class="c-client-edit--li-title">
                  <span>联系人标签</span>
                </li>
                <li
                    v-for="m in item.contactTagList"
                    :key="m.id"
                    class="c-client-edit--li"
                    style="display: inline-block"
                >
                  <div
                      :class="{
                      'm-select-input-score':
                        m.isScores &&
                        m.selectValue !== '' &&
                        m.isClickAdd !== true &&
                        ((m.code === 'ClientCharacter' && CanPersonalitySign) ||
                          (m.code !== 'ClientCharacter' && CanSign)),
                      'm-select-attrinput-score':
                        m.isScores &&
                        ((m.code === 'ClientCharacter' &&
                          !CanPersonalitySign) ||
                          (m.code !== 'ClientCharacter' && !CanSign) ||
                          m.selectValue === '' ||
                          m.isClickAdd),
                    }"
                  >
                    <el-tooltip class="item" effect="dark"  :content="m.Multiple ? m.selectValue.join(','):  m.selectValue" placement="top">
                    <znl-input
                        v-model="m.selectValue"
                        v-required="m.isRequired"
                        :disabled="isDetail || m.isReadonly"
                        :placeholder="isDetail ? '' : '请选择'"
                        :select-options="m.lines"
                        :title="`${m.name}:`"
                        :multiple="m.Multiple"
                        border
                        clearable
                        form-type="select"
                        title-width="115px"
                        width="300px"
                    >
                    </znl-input>
                    </el-tooltip>
                    <span v-if="m.isScores" class="m-select-span-score"
                    >分</span
                    >
                    <span
                        v-if="
                        m.isScores &&
                        ( m.Multiple ? m.selectValue.length > 0 : m.selectValue !== '') &&
                        m.isClickAdd !== true &&
                        ((m.code === 'ClientCharacter' && CanPersonalitySign) ||
                          (m.code !== 'ClientCharacter' && CanSign))
                      "
                        class="m-select-span-add"
                        style=" margin-right: 4px;"
                        @click="LableAddOne(m, item.contactId)"
                    >+</span
                    >
                    <span v-if="m.Cnt" class="m-select-span-add" style="margin-left: -9px;">
                      √
                    </span>
                  </div>
                </li>
              </ul>
            </section>
          </el-tab-pane>
        </el-tabs>
      </div>
    </section>
    <!--    公司属性-->
    <section class="p-client-edit-attribute">
      <div class="header">
        <p class="p-client-edit_title"><span>属性信息： </span></p>
      </div>

      <div class="p-client-edit-attribute--box">
        <p
            v-for="(item, index) in editItem.attributeList"
            :key="index"
            :class="{ 'm-select-attrinput-score': item.isScores }"
            style="display: inline-block"
        >
          <el-tooltip class="item" effect="dark"  :content="item.Multiple ? item.selectValue.join(','):  item.selectValue" placement="top">
            <znl-input
                v-model="item.selectValue"
                v-required="item.isRequired"
                :disabled="isDetail || item.isReadonly"
                :placeholder="isDetail ? '' : '请选择'"
                :select-options="item.lines"
                :title="`${item.name}:`"
                border
                clearable

                :multiple="item.Multiple"
                form-type="select"
                title-width="100px"
                width="300px"
                @change="onAttributeChange"
            >
            </znl-input>
          </el-tooltip>
          <span v-if="item.isScores" class="m-select-span-score">分</span>
        </p>
      </div>
    </section>

    <!--    财务-->
    <section class="p-client-edit-finance">
      <div class="header">
        <p class="p-client-edit_title"><span>财务属性： </span></p>
      </div>

      <div>
        <ul class="c-client-edit--ul">
          <li class="c-client-edit--li">
            <znl-input
                v-model="editItem.financeCapital"
                v-required="requiredData['financeCapital_required']"
                :disabled="isDetail"
                border
                title="注册资金:"
                title-width="100px"
                width="300px"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`financeCapital_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
            <znl-input
                v-model="editItem.financeScope"
                v-required="requiredData['financeScope_required']"
                :disabled="isDetail"
                border
                title="经营范围:"
                title-width="100px"
                width="300px"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`financeScope_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
            <znl-input
                v-model="editItem.financeLicense"
                v-required="requiredData['financeLicense_required']"
                :disabled="isDetail"
                border
                title="营业执照号:"
                title-width="100px"
                width="300px"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`financeLicense_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
            <znl-input
                v-model="editItem.financeVATNumber"
                v-required="requiredData['financeVATNumber_required']"
                :disabled="isDetail"
                border
                title="增值税号:"
                title-width="100px"
                width="300px"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`financeVATNumber_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
          </li>
          <li class="c-client-edit--li">
            <znl-input
                v-model="editItem.financeDGName"
                v-required="requiredData['financeDGName_required']"
                :disabled="isDetail"
                border
                title="对公名称:"
                title-width="100px"
                width="300px"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`financeDGName_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
            <znl-input
                v-model="editItem.financeBankPhone"
                v-required="requiredData['financeBankPhone_required']"
                :disabled="isDetail"
                border
                title="开户电话:"
                title-width="100px"
                width="300px"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`financeBankPhone_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
            <znl-input
                v-model="editItem.financeDGAccount"
                v-required="requiredData['financeDGAccount_required']"
                :disabled="isDetail"
                border
                title="对公账户:"
                title-width="100px"
                width="300px"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`financeDGAccount_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
            <znl-input
                v-model="editItem.financeBankName"
                v-required="requiredData['financeBankName_required']"
                :disabled="isDetail"
                border
                title="开户银行:"
                title-width="100px"
                width="300px"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`financeBankName_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
          </li>
          <li class="c-client-edit--li">
            <znl-input
                v-model="editItem.financeAddress"
                v-required="requiredData['financeAddress_required']"
                :disabled="isDetail"
                border
                title="开户地址:"
                title-width="100px"
                width="610px"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`financeAddress_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
            <znl-input
                v-model="editItem.financeDebt"
                v-required="requiredData['financeDebt_required']"
                :disabled="isDetail"
                border
                title="挂欠额度:"
                title-width="100px"
                type="number"
                width="300px"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`financeDebt_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
            <div
                v-selectScore="scoreData[`financeIsInvoice_score`]"
                class="m-inline-block"
            >
              <znl-input
                  v-model="editItem.financeIsInvoice"
                  v-required="requiredData['financeIsInvoice_required']"
                  :disabled="isDetail"
                  :placeholder="isDetail ? '' : '请选择'"
                  :select-options="invoiceOptions"
                  border
                  clearable
                  form-type="select"
                  title="是否开票:"
                  title-width="100px"
                  width="300px"
              >
              </znl-input>
            </div>
          </li>
          <li class="c-client-edit--li">
            <div v-select-score="scoreData[`financeFrom_score`]">
              <znl-input
                  v-model="editItem.financeFrom"
                  v-required="requiredData['financeFrom_required']"
                  :disabled="isDetail"
                  :select-options="financeFromOptions"
                  border
                  clearable
                  form-type="select"
                  title="结算方式:"
                  title-width="100px"
                  width="210px"
              >
              </znl-input>
            </div>

            <znl-input
                v-model="editItem.financeFromDate"
                :disabled="isDetail"
                border
                width="80px"
            >
              <span slot="suffix" style="color: #0a76a4">天</span>
            </znl-input>
            <znl-input
                v-model="editItem.financeSendAddress"
                v-required="requiredData['financeSendAddress_required']"
                :disabled="isDetail"
                border
                title="寄送信息:"
                title-width="100px"
                width="610px"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`financeSendAddress_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
            <div
                v-selectScore="scoreData[`financeTaxRate_score`]"
                class="m-inline-block"
            >
              <znl-input
                  v-model="editItem.financeTaxRate"
                  v-required="requiredData['financeTaxRate_required']"
                  :disabled="isDetail"
                  :select-options="taxRatesOptions"
                  border
                  clearable
                  form-type="select"
                  title="税率:"
                  title-width="100px"
                  width="300px"
              >
              </znl-input>
            </div>
          </li>
          <li class="c-client-edit--li">
            <div
                v-selectScore="scoreData[`financeCurrency_score`]"
                class="m-inline-block"
            >
              <znl-input
                  v-model="editItem.financeCurrency"
                  v-required="requiredData['financeCurrency_required']"
                  :disabled="isDetail"
                  :select-options="currencyOption"
                  border
                  clearable
                  form-type="select"
                  title="结算币种:"
                  title-width="100px"
                  width="300px"
              >
              </znl-input>
            </div>

            <znl-input
                v-model="editItem.financeInvoiceRemark"
                v-required="requiredData['financeInvoiceRemark_required']"
                :disabled="isDetail"
                border
                title="开单备注:"
                title-width="100px"
                width="610px"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`financeInvoiceRemark_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
          </li>
          <li class="c-client-edit--li">
            <znl-input
                v-model="editItem.financeNewQuota"
                v-required="requiredData['financeNewQuota_required']"
                :disabled="true"
                border
                title="最新额度:"
                title-width="100px"
                type="number"
                width="300px"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`financeNewQuota_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
            <znl-input
                v-model="editItem.financeRemainQuota"
                v-required="requiredData['financeRemainQuota_required']"
                :disabled="true"
                border
                title="剩余额度:"
                title-width="100px"
                type="number"
                width="300px"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`financeRemainQuota_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
            <znl-input
                v-model="editItem.financeReceiveQuota"
                v-required="requiredData['financeReceiveQuota_required']"
                :disabled="true"
                border
                title="预收款额度:"
                title-width="100px"
                type="number"
                width="300px"
            >
              <span
                  slot="suffix"
                  v-score="scoreData[`financeReceiveQuota_score`]"
                  style="color: #0a76a4"
              ></span>
            </znl-input>
          </li>
        </ul>
      </div>
    </section>

    <!-- 跟进记录-->
    <section class="p-client-edit-follow" >
      <div class="header">
        <p class="p-client-edit_title"><span>维护信息:         <a style="float:right;color:blue" @click="OnFollow()">去跟进</a></span></p>
      </div>
      <div class="inquire-table">
        <grid
            ref="listfollow"
            :columns="followColumns"
            :datas="followSource"
            :page-index="pagination.page"
            :page-size="pagination.limit"
            :total-count="pagination.total"
            headerHeight="35"
            type="base"
        >
        </grid>
      </div>
    </section>
    <!-- 询价记录-->
    <section class="p-client-edit-inquire">
      <div class="header">
        <p class="p-client-edit_title"><span>历史询价信息： </span></p>
      </div>
      <div class="inquire-table">
        <grid
            ref="listinquire"
            :columns="inquireColumns"
            :datas="inquireSource"
            :page-index="inquirePagination.page"
            :page-size="inquirePagination.limit"
            :total-count="inquirePagination.total"
            headerHeight="35"
            type="base"
        >
        </grid>
      </div>
    </section>



    <!--    保存-->
    <section v-if="!isDetail" class="p-client-edit-footer">
      <el-button
          :disabled="isSaveShow"
          :loading="saveLoading"
          size="medium"
          type="primary"
          @click="onSave"
      >保存
      </el-button>
    </section>
  </div>
</template>
<script>
import { getTagAndAttribute } from "@/api/client";
import {
  editClientInfo,
  GetClickDetails,
  getClientInfo,
  newClientInfo,
  VerificationCusterCode,
  VerificationCusterName,
  getInquireList,
} from "@/api/client";
import{
  getInfo
} from "@/api/user";
import {
  contactMap,
  editItemMap,
  followListMap,
  followColumns,
  inquireColumns,
} from "./Modules/List/dataMap";
import CityView from "@/components/City";

import { mixin as directives } from "./script/directives";
import {
  invoiceOptions,
  financeFromOptions,
  genderOptions,
  clientIsUseOption,
  contactInfo,
} from "./script/initData";

import ScoreView from "./Modules/List/Statistics/ScoreView";

import {
  setRequiredOrScore,
  initRequiredCodes,
  initScoresCodes,
} from "./script/form";

import { mapGetters } from "vuex";

const clientRe = /^client/;
const financeRe = /^finance/;

export default {
  name: "editClient",
  mixins: [directives],
  components: {
    CityView,
    ScoreView,
  },
  computed: {
    ...mapGetters(["currencyOption", "taxRatesOptions"]),
  },
  data() {
    return {
      seen:false,
      current:0,
      initId: 0,
      //跟进记录列表表头
      followColumns: followColumns,
      //跟进记录列表数据源
      followSource: [],
      pagination: {
        page: 1,
        limit: 20,
        total: 40,
      },
      //询价记录列表表头
      inquireColumns: inquireColumns,
      //询价记录列表数据源
      inquireSource: [],
      //询价记录列表翻页
      inquirePagination: {
        page: 1,
        limit: 20,
        total: 40,
      },

      id: 0,
      isSaveShow: false,
      saveLoading: false,

      editItem: {
        // 客户信息
        clientCode: "", // 客户编码
        clientName: "", // 客户名称
        clientPhone: "", // 固定电话
        clientCustomerQQ: "", // 客户QQ
        clientCustomerPhone: "", // 客户电话
        clientFax: "", // 传真
        clientShortName: "", // 简称
        clientProvince: "", // 客户省
        clientCity: "", // 客户市
        clientArea: "", // 客户区
        clientAddress: "", // 客户地址
        clientReceiveProvince: "", // 收货省
        clientReceiveCity: "", // 收货市
        clientReceiveArea: "", // 收货区
        clientReceiveAddress: "", // 收货地址
        OuterId:'',
        clientResume: "", // 客户简历
        clientRemark: "", // 客户被追
        clientOrganization: "", // 所属机构
        clientOrganizationName: "", // 所属人员
        clientOrganizationSection: "", // 所属人员
        clientIsUse: 0,
        clientOrderCount: "",
        clientFollowCount: 0,
        clientCommonCount: 0,
        clientEnquiryCount: "",
        clientFollowWeekCount:0,
        clientFollowMonthCount:0,
        clientFollowSeasonCount:0,
        clientCommonMonthCount:0,
        clientCommonWeekCount:0,
        clientCommonSeasonCount:0,
        UserName:"",//所属人
        OrgName:"",//所属机构
        DeptName:"",//所属部门
        // 联系人信息
        contactList: [],

        // 财务信息
        financeCapital: "", //注册资金
        financeScope: "", // 经营范围
        financeLicense: "", // 营业执照号
        financeVATNumber: "", //增值税号
        financeDGName: "", // 对公名称
        financeDGAccount: "", // 对公账户
        financeBankPhone: "", // 开户电话
        financeBankName: "", // 开户银行
        financeAddress: "", // 开户地址
        financeDebt: "", // 欠款额度
        financeIsInvoice: "", //是否开票
        financeFrom: "", // 结算方式
        financeFromDate: "", // 结算方式天数
        financeSendAddress: "", //寄送地址
        financeTaxRate: "", // 税率
        financeCurrency: "", //结算币种
        financeInvoiceRemark: "", // 开票备注
        financeNewQuota: "", // 最新额度
        financeRemainQuota: "", // 剩余额度
        financeReceiveQuota: "", // 预收款额度

        // 属性信息
        attributeList: [],


        //出库（利润）金额
        Amount:0,
        ProfitAmount:0,
        ThisMonthAmount:0,
        ThisMonthProfitAmount:0,
        ThisQuarterAmount:0,
        ThisQuarterProfitAmount:0,
        ThisYearAmount:0,
        ThisYearProfitAmount:0,
      },
      //下单统计
      orderCountItem:{
        OrderCount:0,
        ThisWeekCount:0,
        ThisMonthCount:0,
        ThisQuarterCount:0
      },
      //询价统计
      InquireCountItem:{
        OrderCount:0,
        ThisWeekCount:0,
        ThisMonthCount:0,
        ThisQuarterCount:0
      },

      clientIsUseOption: clientIsUseOption,
      clientOrganizationList: [],
      initTagList: [],
      initAttribute: [],
      contactInfo: contactInfo,

      isEdit: false,
      editableTabsValue: "1",
      isDetail: false,

      invoiceOptions: invoiceOptions,
      financeFromOptions: financeFromOptions,
      genderOptions: genderOptions,

      selectTagList: [],
      selectAttribute: [],

      cityWidth: {
        provinceWidth: "90px",
        cityWidth: "90px",
        areaWidth: "90px",
      },

      clientAddress: {
        province: null,
        city: null,
        area: null,
      },
      receiveAddress: {
        province: null,
        city: null,
        area: null,
      },
      receiveAddressArry: [
        {
          Pca: {
            province: null,
            city: null,
            area: null,
          },
          Id: 0,
          IsDefault: true,
          Address: "",
        },
      ],

      isAddress: false,
      initContactIds: [],
      scoreList: [],
      followList: [],

      requiredData: {},
      scoreData: {},

      CanSign: false,
      CanPersonalitySign: false,
    };
  },
  watch: {
    // $route(route) {
    //   const { id } = route.query;
    //   this.initId = id;
    //   const initId = this.initId;
    //   console.log(id, initId)
    //   if (id === initId) return;
    //   const { name } = route;
    //   if (name !== "editClient" && name !== 'detailClient') return;
    //   this.onInit();
    // },
  },
  methods: {

    async onInit() {
      this.handleRoute(this.$route);
      await this.setData();
    },
    // 初始化表格
    initColumns() {
      const self = this;
      this.$refs.listinquire.changeColumns(inquireColumns);
    },
    setButtons(params) {
      let data = params.data;
      let buttons = [];
      let self = this;

      const auditBtn = {
        text: "查看附件",
        click() {
          self.OnFiles(data);
        },
      }
      buttons.push(auditBtn)
      return buttons;
    },
    async OnFiles(data) {
      window.open(data.InquirePhoto)
    },
    handleRoute(route) {
      const {
        name,
        query: { id },
      } = route;
      const keys = ["editClient", "newClient", "detailClient"];
      if (!keys.includes(name)) {
        return false;
      }
      this.isEdit = name === "editClient";
      this.isDetail = name === "detailClient";
      if (this.isEdit || this.isDetail) {
        this.id = id;
      }

      this.initId = id;
    },
    // 验证客户编码
    async verifyCode() {
      const CompanyCode = this.editItem.clientCode;
      if (!CompanyCode) return;
      const ret = await VerificationCusterCode({ Id: this.id, CompanyCode });
      ret &&
      this.$message({
        message: "已存在的客户编码",
        type: "error",
        duration: 5 * 1000,
      });
    },
    // 验证客户名称
    async verifyName() {
      const CompanyName = this.editItem.clientName;
      if (!CompanyName) return;
      const ret = await VerificationCusterName({ Id: this.id, CompanyName });
      ret &&
      this.$message({
        message: "已存在的客户名称",
        type: "error",
        duration: 5 * 1000,
      });
    },

    // 获取详情数据
    async getData() {
      const { isEdit, id, isDetail } = this;

      if (!(isDetail || isEdit)) return false;
      const params = {
        CompanyID: id,
        IsEdit: isEdit
      };
      console.log(params)
      return await getClientInfo(params);
    },

    async onSearchInquires(page = 1) {
      const { pagination, id } = this;
      const sendData = {
        Page: page,
        Limit: pagination.limit,
        CompanyID: id,
      };
      const data = await getInquireList(sendData); // 请求接口
      pagination.page = data.Page;
      pagination.total = data.Total;

      let items = data.Items;

      // let newItem = [];
      // if (items.length) {
      //   newItem = this.getListData(items, listDataMap);
      //   newItem.forEach((item, index) => {
      //     item.rowIndex = index + 1;
      //     dateNameArr.forEach((key) => {
      //       item[key] = this.formatDate(item[key], "yyyy-MM-dd hh:mm");
      //     });
      //   });
      // }
      // this.itemSource = newItem;
    },

    // 获取数据
    // 标签 公司属性
    async getAllTagAndAttribute() {
      return await getTagAndAttribute();
    },
    async initTagAndAttribute() {
      const data = await this.getAllTagAndAttribute();
      const tagList = data.filter((item) => item["DictItemType"] === "Lable");
      const attributeList = data.filter(
          (item) => item["DictItemType"] === "Attribute"
      );
      // 储存原始数据
      this.initTagList = this.setTagAndAttributeList(tagList);
      this.initAttribute = this.setTagAndAttributeList(attributeList);
    },
    setTagAndAttributeList(list) {
      // 整理数据
      return list.map((item, index) =>

          Object.assign(
              {},
              {
                indexId: index + 1,
                itemId: item["DictItemId"],
                itemName: item["DictItemName"],
                itemType: item["DictItemType"],
                itemCode: item["DictItemCode"],
                isRequired: initRequiredCodes.includes(item["DictItemCode"]),
                isScores: initScoresCodes.includes(item["DictItemCode"]),
                Multiple: item['Multiple'],
                isReadonly: item['IsReadonly'],
                Cnt: item['Cnt'],
                lines: item["DictItemLines"].map((line) =>
                    Object.assign(
                        {},
                        {
                          itemId: item["DictItemId"],
                          itemName: item["DictItemName"],
                          lineId: line["DictItemLineId"],
                          lineName: line["DisctItemLineName"],
                          lineCode: line["DisctItemLineCode"],
                        }
                    )
                ),
              }
          )
      );
    },
    OnFollow()
    {
      const {  id } = this;
      this.$router.push({
        name: "followClient",
        params: {
          clientId: id
        },
        query: {
          id: id,
        },
      });
    },
    async setData() {
      const { isEdit, isDetail } = this;
      let data = null;
      // 获取原始数据
      await this.initTagAndAttribute();
      const [...initTagList] = this.initTagList.map((item) =>
          Object.assign({}, item)
      );
      const [...initAttribute] = this.initAttribute.map((item) =>
          Object.assign({}, item)
      );
      const tagChoiceList = ["CustomLableExpress", "CustomLablePersonality"];
      // 处理原始标签数据
      this.contactInfo.contactTagList = initTagList.map((line) => {
        return {
          id: line.itemId,
          name: line.itemName,
          code: line.itemCode,
          // selectValue: tagChoiceList.includes(line.itemCode) ? [] : '',
          // isChoice: tagChoiceList.includes(line.itemCode),
          isRequired: line.isRequired,
          isReadonly: line.isReadonly,
          isScores: line.isScores,
          selectValue: line.Multiple?[]:"",
          Multiple: line.Multiple,
          Cnt: line.Cnt,
          isChoice: tagChoiceList.includes(line.itemCode),
          lines: line.lines.map((m) =>
              Object.assign(
                  {},
                  {
                    key: m.lineCode,
                    value: m.lineName,
                  }
              )
          ),
        };
      });
      // 处理原始属性数据
      let newAttributeList = initAttribute.map((line) => {
        return {
          id: line.itemId,
          name: line.itemName,
          code: line.itemCode,
          selectValue: line.Multiple?[]:"",
          isRequired: line.isRequired,
          isReadonly: line.isReadonly,
          isScores: line.isScores,
          Multiple: line.Multiple,
          Cnt: line.Cnt,
          lines: line.lines.map((m) =>
              Object.assign(
                  {},
                  {
                    key: m.lineCode,
                    value: m.lineName,
                  }
              )
          ),
        };
      });
      // 新增
      if (!isEdit) {
        // 属性
        this.editItem.attributeList = newAttributeList;
        // this.editItem.contactList = this.contactInfo;
        var Current=await getInfo();

        this.editItem.DeptName=Current.DeptName
        this.editItem.OrgName=Current.OrgName
        this.editItem.UserName=Current.userName
        this.onAddContact();
      }

      if (isEdit || isDetail) {
        data = await this.getData();
        const {
          AttributeInfoSend,
          BaseInfoSend,
          CusterContactsSend,
          FinancialInfoSend,
          HistoricalInquirySend,
          ListFllowUpInfoSend,
          Statistics,
          CanSign,
          CanPersonalitySign,
          CusterAddressSend,
          OrderCountInfo,
          InquireCountInfo,
        } = data;
        if(CusterContactsSend==null)
        {
           this.onAddContact();
        }
        this.editItem.OuterId=BaseInfoSend.OuterId
        this.CanSign = CanSign ?? false;
        this.CanPersonalitySign = CanPersonalitySign ?? false;
        const { editItem } = this;
      
        this.editItem.DeptName=BaseInfoSend.DeptName
        // this.editItem.OrgName=BaseInfoSend.OrgName
        this.editItem.UserName=BaseInfoSend.AttachName
        this.orderCountItem.OrderCount=OrderCountInfo.OrderCount
        this.orderCountItem.ThisWeekCount=OrderCountInfo.ThisWeekCount
        this.orderCountItem.ThisMonthCount=OrderCountInfo.ThisMonthCount
        this.orderCountItem.ThisQuarterCount=OrderCountInfo.ThisQuarterCount
        this.InquireCountItem.OrderCount=InquireCountInfo.OrderCount
        this.InquireCountItem.ThisWeekCount=InquireCountInfo.ThisWeekCount
        this.InquireCountItem.ThisMonthCount=InquireCountInfo.ThisMonthCount
        this.InquireCountItem.ThisQuarterCount=InquireCountInfo.ThisQuarterCount

        this.editItem.Amount=BaseInfoSend.Amount
        this.editItem.ProfitAmount=BaseInfoSend.ProfitAmount
        this.editItem.ThisMonthAmount=BaseInfoSend.ThisMonthAmount
        this.editItem.ThisMonthProfitAmount=BaseInfoSend.ThisMonthProfitAmount
        this.editItem.ThisQuarterAmount=BaseInfoSend.ThisQuarterAmount
        this.editItem.ThisQuarterProfitAmount=BaseInfoSend.ThisQuarterProfitAmount
        this.editItem.ThisYearAmount=BaseInfoSend.ThisYearAmount
        this.editItem.ThisYearProfitAmount=BaseInfoSend.ThisYearProfitAmount
        Object.entries(editItemMap).forEach(([key, src]) => {
          if (clientRe.test(key)) {
            editItem[key] = BaseInfoSend[src];
          }
          if (financeRe.test(key)) {
            if (key === "financeIsInvoice") {
              editItem[key] = FinancialInfoSend[src] ? "是" : "否";
            } else {
              editItem[key] = FinancialInfoSend[src];
            }
          }
        });

        //收货地址
        let receiveAddressList = [
          {
            Pca: {
              province: null,
              city: null,
              area: null,
            },
            Id: 0,
            IsDefault: true,
            Address: "",
          },
        ];
        if (CusterAddressSend.length) {
          receiveAddressList = [];
          CusterAddressSend.forEach((a) => {
            const reAddressItem = {
              Pca: {
                province: a.Province,
                city: a.City,
                area: a.Area,
              },
              Id: a.Id,
              IsDefault: a.IsDefault,
              Address: a.Address,
            };
            receiveAddressList.push(Object.assign({}, reAddressItem));
          });
        }

        this.receiveAddressArry = receiveAddressList;

        // 处理地址
        const cityMap = ["province", "city", "area"];
        const addressMap = ["clientAddress", "receiveAddress"];
        addressMap.forEach((m) => {
          const str = m === "clientAddress" ? "client" : "clientReceive";
          cityMap.forEach((key) => {
            let data = null;
            const code = `${str}${key.replace(key[0], key[0].toUpperCase())}`;
            if (editItem[code]) {
              data = editItem[code];
            }
            this[m][key] = data;
          });
        });

        // 处理联系人标签
        let contactList = [];
        const testArr = (value) => {
          if (!value) return []
          if (value.length === 0) return []
          const newValue = value.split(",");
          return newValue;
        };
        CusterContactsSend.reduce((acc, cur) => {
          let targetItem = {};
          Object.entries(contactMap).forEach(([key, src]) => {
            if (key === "contactGender") {
              targetItem[key] = cur[src] ? "男" : "女";
            } else if (key !== "contactTagList") {
              targetItem[key] = cur[src];
            } else {
              targetItem.selectTagList = cur[src].map((item) => {
                return {
                  Cnt: item.Cnt,
                  code: item.ItemCode,
                  selectValue: item.ItemValue // testArr(item.ItemValue),
                };
                
              });
            }
          });
          acc.push(targetItem);
          return acc;
        }, contactList);

        contactList.forEach((item) => {
          const [...contactTagList] = this.contactInfo.contactTagList.map(
              (item) => Object.assign({}, item)
          );
          const [...selectTagList] = item.selectTagList.map((item) =>
              Object.assign({}, item)
          );
          item.contactTagList = this.onMergeArr(contactTagList, selectTagList);
          // 多选时将字符串值变成数组，否则选中框不会显示值
          item.contactTagList.forEach(a=>{
            if(a.Multiple){
              a.selectValue=testArr(a.selectValue)
            }
          })
        });
        await this.onFormatContact(contactList);

        // 储存原始联系人Id
        this.initContactIds = contactList.map((item) => item.contactId);
        // 处理公司属性
        const attributeList = AttributeInfoSend.map((item) =>
            Object.assign(
                {},
                {
                  code: item.ItemCode,
                  selectValue: item.ItemValue,
                }
            )
        );
        this.editItem.attributeList = await this.onMergeArr(
            newAttributeList,
            attributeList
        );
        // 多选时将值变成数组，否则选中框不会显示值
        this.editItem.attributeList.forEach(a=>{
          if(a.Multiple){
            a.selectValue=testArr(a.selectValue)
          }
        })

        // 处理得分统计数据
        this.setScoreList(Statistics);
     
        if (ListFllowUpInfoSend.length) {
          this.followList = this.getListData(
              ListFllowUpInfoSend,
              followListMap
          );


          this.followSource=ListFllowUpInfoSend
          this.inquireSource=HistoricalInquirySend


        }
      }
    },

    // 处理得分统计数据
    setScoreList(data) {
      const scoreList = [];
      const {
        TaskCompletion,
        AttrbutieCompletion,
        labelCompletion,
        CustomerInformationCompletion,
      } = data;

      // 处理客户得分项
      const clientData = Object.assign({}, CustomerInformationCompletion);
      const newClientData = {
        title: "客户信息完成度",
        ratio: clientData["PercentComplete"],
        type: "static",
        lines: [
          {
            code: "客户基本信息",
            value: clientData["BaseCompletion"],
          },
          {
            code: "联系人基本信息",
            value: clientData["CusterContactCompletion"],
          },
          {
            code: "财务基本信息",
            value: clientData["FinancialInfoCompletion"],
          },
        ],
      };

      scoreList.push(newClientData);

      const setLines = (lines) => {
        if (!lines.length) return [];

        return lines.map((item) =>
            Object.assign(
                {},
                {
                  code: item.Code,
                  value: item.Value,
                }
            )
        );
      };

      // 处理属性
      const attributeData = Object.assign({}, AttrbutieCompletion);

      const newAttributeData = {
        title: "属性信息完成度",
        ratio: attributeData["AttributeComplete"],
        type: "move",
        lines: setLines(attributeData["attrbute"]),
      };

      scoreList.push(newAttributeData);

      // 处理tag
      const labelData = Object.assign({}, labelCompletion);
      const newLabelData = {
        title: "标签信息完成度",
        ratio: labelData["LabelComplete"],
        type: "move",
        lines: setLines(labelData["LabelList"]),
      };

      // 处理tag
      const taskData = Object.assign({}, TaskCompletion);
      const newTaskData = {
        title: "信息完成度",
        ratio: taskData["TaskInfoCompletion"],
        type: "move",
        lines: setLines(taskData["TaskInfoList"]),
      };

      scoreList.push(newLabelData);
      scoreList.push(newTaskData);

      this.scoreList = scoreList;
    },

    // 合并数组
    onMergeArr(targetData, paramsData) {
      return paramsData.reduce((acc, cur) => {
        const target = acc.find((e) => e.code === cur.code);

        if (target) {
          Object.assign(target, cur);
        }
        return acc;
      }, targetData);
    },

    // 操作
    // 联系人
    onAddContact() {
      const contactInfo = JSON.parse(JSON.stringify(this.contactInfo));
      const [...contactList] = this.editItem.contactList;
      contactList.push(contactInfo);
      this.onFormatContact(contactList);
    },
    onDeleteContact(targetName) {
      const length = this.editItem.contactList.length;
      if (length === 1) {
        this.$message.warning("联系人需要填写，不能全部删除");
        return false;
      }
      const [...contactList] = this.editItem.contactList.filter(
          (item) => item.contactIndex !== targetName
      );
      this.onFormatContact(contactList);
    },
    onFormatContact(list) {
      list.forEach((item, index) => {
        item.contactIndex = (index + 1).toString();
      });
      this.editableTabsValue = list.length.toString();
      this.editItem.contactList = list;
    },

    // 选择属性
    onAttributeChange() {},

    // 同步地址
    onMergeAddress(val) {
      //   clientProvince: '',  // 客户省
      //   clientCity: '', // 客户市
      //   clientArea: '', // 客户区
      //   clientAddress: '', // 客户地址
      //   clientReceiveProvince: '', // 收货省
      //   clientReceiveCity: '',   // 收货市
      //   clientReceiveArea: '',   // 收货区
      //   clientReceiveAddress: '', // 收货地址

      // clientAddress: {
      //   province: null,
      //     city: null,
      //     area: null
      // },
      // receiveAddress: {
      //   province: null,
      //    city: null,
      //     area: null
      // },
      // keys.forEach(key => this.receiveAddress[key] = this.clientAddress[key])
      // this.editItem.clientReceiveAddress = this.editItem.clientAddress
      const keys = ["province", "city", "area"];
      let newItem = this.receiveAddressArry[0];

      if (val) {
        Object.keys(this.clientAddress).forEach((key) => {
          newItem["Pca"][key] =
              this.clientAddress[key]["value"] || this.clientAddress[key];
        });
        newItem.Address = this.editItem.clientAddress;
      } else {
        keys.forEach((key) => {
          newItem["Pca"][key] = null;
        });
        newItem.Address = null;
      }
    },

    // 保存数据
    // 处理参数
    async setParams() {
      const { editItem, initContactIds } = this;
      const { contactList, attributeList } = editItem;
      //  地址信息设置
      const addressMap = ["clientAddress", "receiveAddress"];

      const testType = (value) => {
        return typeof value === "string";
      };

      addressMap.forEach((m) => {
        const str = m === "clientAddress" ? "client" : "clientReceive";
        for (let key in this[m]) {
          const item = this[m][key];
          const code = `${str}${key.replace(key[0], key[0].toUpperCase())}`;
          if (!item) return false;
          if (testType(item)) {
            editItem[code] = item;
          } else {
            editItem[code] = item["value"] ? item["value"] : "";
          }
        }
      });

      let clientInfo = {};
      let financeInfo = {};
      let contactInfo = [];
      let attributeInfo = [];
      Object.entries(editItemMap).forEach(([key, src]) => {
        if (clientRe.test(key)) {
          clientInfo[src] = editItem[key];
        }
        if (financeRe.test(key)) {
          if (key === "financeIsInvoice") {
            financeInfo[src] = editItem[key] === "是";
          } else {
            financeInfo[src] = editItem[key];
          }
        }
      });
      contactList.reduce((acc, cur) => {
        let targetItem = {};
        Object.entries(contactMap).forEach(([key, src]) => {
          if (key === "contactGender") {
            targetItem[src] = cur[key] === 1;
          } else if (key !== "contactTagList") {
            targetItem[src] = cur[key];
          } else {
            targetItem[src] = cur[key].map((item) => {
              return {
                ItemCode: item.code,
                ItemValue:
                    item.selectValue instanceof Array
                        ? item.selectValue.join(",")
                        : item.selectValue,
                Flag: item.flag,
              };
            });
          }
        });
        acc.push(targetItem);
        return acc;
      }, contactInfo);

      attributeInfo = attributeList.map((item) =>
          Object.assign({}, { ItemCode: item.code, ItemValue: item.selectValue instanceof Array
                ? item.selectValue.join(",")
                : item.selectValue})
      );

      // 处理编辑删除数据
      let newContactList = [];
      contactInfo.forEach((item) => {
        if (
            initContactIds.includes(item["ContactId"]) ||
            item["ContactId"] === 0
        ) {
          item.IsDelete = false;
          newContactList.push(item);
        }
      });

      initContactIds.forEach((key) => {
        let newItem = {};
        if (
            !contactList.find((item) => Number(item["contactId"]) === Number(key))
        ) {
          newItem.IsDelete = true;
          newItem.ContactId = key;
          newContactList.push(newItem);
        }
      });

      const newReceiveAddressArry = [];
      const IsString = (val) => {
        const typeOf = Object.prototype.toString;
        if (typeOf.call(val) === "[object String]") return true;
        return typeOf.call(val) !== "[object Object]";
      };
      this.receiveAddressArry.forEach((a) => {
        const pca = a.Pca || {};
        const reAddressItem = {
          Province: IsString(pca.province) ? pca.province : pca.province.value,
          City: IsString(pca.city) ? pca.city : pca.city.value,
          Area: IsString(pca.area) ? pca.area : pca.area.value,
          Id: a.Id,
          IsDefault: a.IsDefault,
          Address: a.Address,
        };
        newReceiveAddressArry.push(reAddressItem);
      });

      return {
        BaseInfoSend: clientInfo,
        CusterContactsSend: newContactList,
        FinancialInfoSend: financeInfo,
        AttributeInfoSend: attributeInfo,
        CusterAddressSend: newReceiveAddressArry,
      };
    },
    async selectLog(data, cid) {

      const { id } = this;
      let sendData = {
        CompanyID: id,
        ViewFiled: data,
        ContactID: cid,
      };

      const { isEdit, isDetail } = this;

      if (isDetail == true) {
        if (data == "CompanyPhone") {
          const clientPhone = await GetClickDetails(sendData);
          this.editItem.clientPhone = clientPhone.ViewInfo;
          console.log(this.editItem.clientPhone);
        }
        if (data == "CustomerPhone") {
          const clientPhone = await GetClickDetails(sendData);
          this.editItem.clientCustomerPhone = clientPhone.ViewInfo;
          console.log(this.editItem.clientCustomerPhone);
        }
        if (data == "CustomerQQ") {
          const clientPhone = await GetClickDetails(sendData);
          this.editItem.clientCustomerQQ = clientPhone.ViewInfo;
          console.log(this.editItem.clientCustomerQQ);
        }
        if (data == "Fax") {
          const clientPhone = await GetClickDetails(sendData);
          this.editItem.clientFax = clientPhone.ViewInfo;
        }
        // if (data == "Email") {
        //   const clientPhone = await GetClickDetails(sendData);
        //   this.editItem.contactList.forEach((item, index) => {
        //     let contactId = item.contactId;
        //     if (contactId == cid) {
        //       this.editItem.contactList[index].contactEmail =
        //         clientPhone.ViewInfo;

        //     }
        //   });
        // }
        if (data == "WeChat") {
          const clientPhone = await GetClickDetails(sendData);
          this.editItem.contactList.forEach((item, index) => {
            let contactId = item.contactId;
            if (contactId == cid) {
              this.editItem.contactList[index].contactWechat =
                  clientPhone.ViewInfo;

            }
          });
        }
        if (data == "QQ") {
          const clientPhone = await GetClickDetails(sendData);
          this.editItem.contactList.forEach((item, index) => {

            let contactId = item.contactId;
            if (contactId == cid) {
              this.editItem.contactList[index].contactQQ = clientPhone.ViewInfo;

            }
          });
        }
        // if (data == "WangWang") {
        //   const clientPhone = await GetClickDetails(sendData);
        //   this.editItem.contactList.forEach((item, index) => {
        //     let contactId = item.contactId;
        //     if (contactId == cid) {
        //       this.editItem.contactList[index].contactWW = clientPhone.ViewInfo;
        //     }
        //   });
        // }
        if (data == "Phone") {
          const clientPhone = await GetClickDetails(sendData);
          this.editItem.contactList.forEach((item, index) => {
            let contactId = item.contactId;
            if (contactId == cid) {
              this.editItem.contactList[index].contactPhone =
                  clientPhone.ViewInfo;

            }
          });
        }
        if (data == "MobilePhone") {
          const clientPhone = await GetClickDetails(sendData);
          this.editItem.contactList.forEach((item, index) => {
            let contactId = item.contactId;
            if (contactId == cid) {
              this.editItem.contactList[index].contactMobilePhone =
                  clientPhone.ViewInfo;

            }
          });
         }
      }
    },
    async leave(data, cid)
    {
      const { id } = this;
      let sendData = {
        CompanyID: id,
        ViewFiled: data,
        ContactID: cid,
      };

      const { isEdit, isDetail } = this;

      if (isDetail == true) {

        if (data == "CompanyPhone") {
          const datainfo = await this.getData();
          console.log(datainfo)
          this.editItem.clientPhone = datainfo.BaseInfoSend.Phone;
        }
        if (data == "CustomerPhone") {
          const datainfo = await this.getData();
          console.log(datainfo)
          this.editItem.clientCustomerPhone = datainfo.BaseInfoSend.CustomerPhone;
        }
        if (data == "CustomerQQ") {
          const datainfo = await this.getData();
          console.log(datainfo)
          this.editItem.clientCustomerQQ =  datainfo.BaseInfoSend.CustomerQQ;

        }
        if (data == "Fax") {
          const datainfo = await this.getData();
          this.editItem.clientFax = datainfo.BaseInfoSend.Fax;
          console.log( this.editItem.clientFax)
        }
        // if (data == "Email") {
        //     const datainfo = await this.getData();
        //   this.editItem.contactList.forEach((item, index) => {

        //     let contactId = item.contactId;
        //     if (contactId == cid) {
        //       this.editItem.contactList[index].contactEmail =
        //         datainfo.CusterContactsSend[index].Email;

        //     }
        //   });
        // }
        if (data == "WeChat") {
          const datainfo = await this.getData();
          this.editItem.contactList.forEach((item, index) => {

            let contactId = item.contactId;
            if (contactId == cid) {
              this.editItem.contactList[index].contactWechat =
                  datainfo.CusterContactsSend[index].WeChat;

            }
          });
        }
        if (data == "QQ") {
          const datainfo = await this.getData();
          this.editItem.contactList.forEach((item, index) => {

            let contactId = item.contactId;
            if (contactId == cid) {
              this.editItem.contactList[index].contactQQ = datainfo.CusterContactsSend[index].QQ;

            }
          });
        }
        // if (data == "WangWang") {
        //      const datainfo = await this.getData();
        //   this.editItem.contactList.forEach((item, index) => {
        //     let contactId = item.contactId;
        //     if (contactId == cid) {
        //       this.editItem.contactList[index].contactWW = datainfo.CusterContactsSend[index].WangWang;
        //     }
        //   });
        // }
         if (data == "MobilePhone") {
          const datainfo = await this.getData();
          console.log(datainfo)
          this.editItem.contactList.forEach((item, index) => {
            let contactId = item.contactId;
            if (contactId == cid) {
              this.editItem.contactList[index].contactMobilePhone =
                  datainfo.CusterContactsSend[index].Mobile;

            }
          });
         }
        if (data == "Phone") {
          const datainfo = await this.getData();
          console.log(datainfo)
          this.editItem.contactList.forEach((item, index) => {
            let contactId = item.contactId;
            if (contactId == cid) {
              this.editItem.contactList[index].contactPhone =
                  datainfo.CusterContactsSend[index].MobilePhone;

            }
          });
         
        }
        
      }
    },
    async SelectCount(CountType){
      console.log(this.$isClient)
      if(this.$isClient)
      {
        let data={"CompanyGuid": this.editItem.OuterId, "CompanyName": this.editItem.clientName, "ModuleName": CountType}
        console.log(CountType)
        window.cefQuery({
          request: "getOpenTabPage",
          data: JSON.stringify(data),
          onSuccess: res => {
            console.log(res);
          },
          onFailure: (code, err) => {
            console.log(code, err);
          },
          persistent: false
        })
      }

    },
    async onSave() {
      this.saveLoading = true;
      const params = await this.setParams();
      const { isEdit, id } = this;
      let paramId = 0;
      this.isSaveShow = true;
      if (isEdit) {
        params.CompanyID = id;
        try {
          await editClientInfo(params);
          this.saveLoading = false;
          paramId = id;
        } finally {
          this.isSaveShow = false;
          this.saveLoading = false;
        }
      } else {
        try {
          paramId = await newClientInfo(params);
          this.saveLoading = false;
        } finally {
          this.isSaveShow = false;
          this.saveLoading = false;
        }
      }
      this.$message.success('保存成功');
      this.$router.push({
        name: "detailClient",
        query: {
          id: Number(paramId),
        },
      });
      await this.$store.dispatch("tagsView/delView", this.$route);
    },
    // 联系人标签点击+1按钮
    async LableAddOne(item, contactId) {
      const { editItem } = this;
      const { contactList } = editItem;
      const newContactList = [...contactList];
      if (item.selectValue !== null && item.selectValue !== "") {
        const contactEntity = newContactList.find(
            (a) => Number(a.contactId) === Number(contactId)
        );
        if (contactEntity != null) {
          const contactTagItem = contactEntity.contactTagList.find(
              (a) => a.code === item.code
          );
          if (contactTagItem != null) {
            contactTagItem.flag = true;
            contactTagItem.isClickAdd = true;
            editItem.contactList = newContactList;
          }
        }

        // if(item.code==='ClientCharacter'){
        //    this.CanPersonalitySign=false
        // }else{
        //    this.CanSign=false
        // }
      } else {
        this.$message.warning("不能选择空值");
      }
    },
    // 点击更多收货地址
    async moreAddress() {
      this.receiveAddressArry.push({
        Pca: {
          province: null,
          city: null,
          area: null,
        },
        Id: 0,
        IsDefault: false,
        Address: "",
      });
    },
    // 删除收货地址
    removeAddress(index) {
      this.receiveAddressArry.splice(index, 1);
    },
  },
  async created() {
    const formData = await setRequiredOrScore();
    await this.onInit();
    this.requiredData = formData.requiredData;
    this.scoreData = formData.scoresData;
  },
  async mounted() {
    this.initColumns();
  },
};
</script>

<style lang="scss">
@import "./style/editClient";
@import "./style/detailClient";
.p-client-edit_right{
  height: 360px;
}
.Mobilephonestyle{
  margin-top: 10px;
}
</style>
