import axios from "axios";
function setData(key, cityData) {
  let data = []
  const newKey = Number(key)
  if (newKey === 1) {
    for (let key in cityData[1]) {
      let item = cityData[1][key];
      for (let city in item) {
        data.push({
          key: parseInt(item[city].code),
          value: item[city].address
        });
      }
    }
    return data;
  } else if (newKey === 120 || newKey === 12016) {
    let items = cityData[newKey];
    items.forEach(item => {
      data.push({
        key: parseInt(item.key),
        value: item.value
      });
    });
  } else {
    let items = cityData[newKey];
    for (let key in items) {
      data.push({
        key: parseInt(key),
        value: items[key]
      });
    }
  }

  return data
}

function getCityDataMap() {
  let cityData = window.ChineseDistricts;
  let cityDataMap = []
  for (let key in cityData) {
    const data = setData(key, cityData)
    cityDataMap.push(...data)
  }
  return cityDataMap
}

export async function getCityData(id) {
  let cityData = window.ChineseDistricts;

  if (cityData === undefined || cityData === null) {
    const url = `${location.protocol}//${location.host}/city.json`;
    const resp = await axios.get(url);
    cityData = resp.data;
    window.ChineseDistricts = cityData;
  }
  let data = [];
  id = id || 1;
  data = setData(id, cityData)
  return data;
}

export const cityDataMap = getCityDataMap()






