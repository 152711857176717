import {editItemMap, contactMap} from "../Modules/List/dataMap";
import {getRequired} from "@/api/client";

let initRequiredCodes = []
let initScoresCodes = []

const setRequiredOrScore = async () => {
  const data = await getRequired()
  console.log(data)
  const {OptionRequierds, OptionScores} = data
  const requiredCodes = OptionRequierds.map(item => item.ItemValue)
  const scoresCodes = OptionScores.map(item => item.ItemValue)

  initRequiredCodes = requiredCodes
  initScoresCodes = scoresCodes


  const requiredData = {}
  const scoresData = {}

  Object.assign(editItemMap, contactMap)
  Object.entries(editItemMap).forEach(([key, src]) => {
    const code = `${key}_required`
    requiredData[code] = !!requiredCodes.includes(src);
  })
  Object.entries(editItemMap).forEach(([key, src]) => {
    const code = `${key}_score`
    scoresData[code] = !!scoresCodes.includes(src);
  })

  return {
    requiredData,
    scoresData
  }
}


export {
  setRequiredOrScore,
  initRequiredCodes,
  initScoresCodes
}
