
const score = {
  update: function (el, binding) {
    const {value} = binding
    if (value) {
      el.innerHTML = '分'
    }
  }
}

const selectScore = {
  update: function (el, binding) {
    const {value} = binding
    if (value) {
      const spanDom = document.createElement('span')
      spanDom.className = 'm-select-span-score'
      spanDom.innerHTML = '分'
      el.className = `m-select-input-score m-select-attrinput-score`
      el.append(spanDom)
    } else {
      el.className = `m-inline-block`
    }
  }
}

const required = {
  update: function (el, binding) {
    const {value} = binding
    const initClassName = el.className
    if (value) {
      el.className = `m-input-required ${initClassName} `
    }
  }
}

const mixin = {
  directives: {
    score: score,
    required: required,
    selectScore: selectScore
  }
}

export {
  mixin
}
