<template>
  <div class="p-detail_statistics-score">
    <el-row :gutter="20">
      <el-col v-for="item in scoreList" :key="item.index" :span="6">
        <div class="grid-content">
          <p class="grid-content-title">
            <span>{{ item.title }}</span>
            <span>{{ item.ratio }}</span>
          </p>
          <ul :class="item.type === 'move'?'grid-content-box_move':'grid-content-box'">
            <li v-for="line in item.lines" :key="line.index">
              <span> {{ line.code }}: </span>
              <i> {{ line.value }}</i>
            </li>
          </ul>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {scoreList} from "../../../script/initData";

export default {
  name: 'StatisticsScoreView',
  props: {
    scoreList: {
      type: Array
    }
  },
  data() {
    return {

    }
  },
  methods: {},
  created() {

  }
}
</script>
<style lang="scss">
.p-detail_statistics-score {
  width: 100%;

  .grid-content {
    border: 1px solid #cccccc;
    height: 130px;
    margin-bottom: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .grid-content-title {
    color: #ffffff;
    font-size: 14px;
    background: #5b91f2;
    display: flex;
    justify-content: space-between;
    padding: 8px;
  }

  .grid-content-box {
    padding: 10px;
    box-sizing: border-box;
    font-size: 12px;

    li {
      margin-bottom: 10px;

      span {
        display: inline-block;
        width: 130px;
      }
    }

    li:last-child {
      margin-bottom: 0;
    }

  }

  .grid-content-box_move {
    padding: 10px;
    box-sizing: border-box;
    font-size: 12px;
    overflow-y: auto;

    li {
      margin-bottom: 10px;

      span {
        display: inline-block;
        width: 160px;
      }
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

}
</style>
