export const invoiceOptions = [
  {
    key: 1,
    value: '是'
  },
  {
    key: 2,
    value: '否'
  }
]
export const financeFromOptions = [
  {
    key: '现结',
    value: '现结'
  },
  {
    key: '月结',
    value: '月结'
  },
  {
    key: '款到发货',
    value: '款到发货'
  },
  {
    key: '货到付款',
    value: '货到付款'
  },
]
export const genderOptions = [
  {
    key: 1,
    value: '男'
  },
  {
    key: 0,
    value: '女'
  }
]
export const clientIsUseOption = [
  {
    key: 1,
    value: '是'
  },
  {
    key: 2,
    value: '否'
  }
]
export const contactInfo = {
  contactIndex: 0,
  contactId: 0,      // 联系人ID
  contactName: '',   // 联系人名称
  contactGender: '', // 联系人性别
  contactBirthday: '', // 联系人生日
  contactPhone: '',   // 联系人电话
  contactQQ: '',  // 联系人QQ
  contactWechat: '', // 联系人微信
  contactWW: '',   // 联系人旺旺
  contactEmail: '', //联系人邮箱
  contactSalesQQ: '', // 联系对接业务员QQ
  contactSalesWechat: '', // 对接业务员微信
  contactSalesWW: '', // 对接业务员旺旺
  contactSalesEmail: '', // 对接业务员邮箱
  contactIdentityZ: '', // 身份证正面
  contactIdentityF: '', // 身份正反面
  contactTagList: []
}
export const scoreList = [
  {
    title: '客户信息完成度',
    ratio: '12%',
    type: 'static',
    lines: [
      {
        code: '客户基本信息',
        value: '0/6'
      },
      {
        code: '联系人基本信息',
        value: '0/6'
      },
      {
        code: '财务基本信息',
        value: '0/6'
      }
    ]
  },
  {
    title: '属性信息完成度',
    ratio: '12%',
    type: 'move',
    lines: [
      {
        code: '合作等级:',
        value: '0/6'
      },
      {
        code: '合作等级：',
        value: '0/6'
      },
      {
        code: '下单周期活跃度：',
        value: '0/6'
      },
      {
        code: '询价周期活跃度：',
        value: '0/6'
      },
      {
        code: '合作模式：',
        value: '0/6'
      },
      {
        code: '合作模式：',
        value: '0/6'
      },
      {
        code: '合作模式：',
        value: '0/6'
      },
      {
        code: '合作模式：',
        value: '0/6'
      },
      {
        code: '合作模式：',
        value: '0/6'
      }
    ]
  },
  {
    title: '标签信息完成度',
    ratio: '12%',
    type: 'move',
    lines: [
      {
        code: '合作等级:',
        value: '0/6'
      },
      {
        code: '合作等级：',
        value: '0/6'
      },
      {
        code: '下单周期活跃度：',
        value: '0/6'
      },
      {
        code: '询价周期活跃度：',
        value: '0/6'
      },
      {
        code: '合作模式：',
        value: '0/6'
      }
    ]
  },
  {
    title: '任务完成度',
    ratio: '12%',
    type: 'move',
    lines: [
      {
        code: '新增客户资料:',
        value: '0/6'
      },
      {
        code: '完成一个客户询价案列：',
        value: '0/6'
      }
    ]
  }
]

